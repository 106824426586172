import { createApp } from 'vue';
import App from '@/modules/App/index.vue';
import routes from './routes';
import store from './store';
import authentication from './authentication';
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

const app = createApp(App);
authentication.initialize().then(_ => {
  app.use(store).use(routes).component('EasyDataTable', Vue3EasyDataTable).mount('#app')
});
// authentication.initialize().then(_ => {
// createApp(App).use(store).use(routes).mount('#app')});