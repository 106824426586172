import { createStore } from 'vuex';
export default createStore({
    state: {
        token:"",
        fetchAWS: false,
        awsData:[],
        awsInboundData:[],
        awsOutboundData:[],
        accessToken: null,
        unique_name:"",
        account: null,
        name:"",
        location_name: "AURORA",
        currentLocation:'Aurora'
    },
    mutations: {
        setAccountDetails(state,{account}){
            state.account = account;
            state.name = account.name;
            state.unique_name = account.username
        },
        setUniqueName(state,{unique_name}){
            state.unique_name = unique_name
        },
        setAccessToken(state, {token}){
            state.accessToken = token;
          },
       
        savetoken(state,{token}){
            state.token = token
        },
        currentLocation(state, {data}){
            state.currentLocation = data

        },
        saveAwsData(state, {data}){
            state.awsData = data
        },
        saveInboundAwsData(state, {data}){
            state.awsInboundData = data
        },
        saveOutboundAwsData(state, {data}){
            state.awsOutboundData = data
        },

        
      
        changefetchAws(state){
            if(state.fetchAWS === true){
                state.fetchAWS = false
            }
            else{
                state.fetchAWS = true
            }
        },
       

      
    },
    actions: {},
    modules: {},
});
