import { PublicClientApplication,InteractionType,
  AccountInfo} from '@azure/msal-browser';
import {AuthCodeMSALBrowserAuthenticationProvider,
  AuthCodeMSALBrowserAuthenticationProviderOptions
} from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import {Client} from '@microsoft/microsoft-graph-client';

import store from '../store';
const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_CLIENT_ID,
    authority:
      'https://login.microsoftonline.com/ff93404f-c1e7-43e5-ab53-a9b9d85c1f98',
    redirectUri: process.env.VUE_APP_ROOT_WEB
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  }
};
var loginRequest = {
  scopes: [] // optional Array<string>
};
const groupId = "7b5d50b6-9972-4601-b990-a5558fd19bc2";
const msalInstance  = new PublicClientApplication(msalConfig);

msalInstance.handleRedirectPromise()
  .then((response) => {
    if (response !== null) {
      const account = msalInstance.getActiveAccount();
      if(!account){
        msalInstance.loginRedirect();
      }
      else{
        store.commit('setAccountDetails', {account});
      }
    }
  })
  .catch((error) => {
      console.error(error);
  });

export default {
  /**
   * @return {Promise}
   */
  initialize() {
    const accounts = msalInstance.getAllAccounts();
    const activeAccount =  msalInstance.getActiveAccount();
     return new Promise<void>((resolve) => {
      if (accounts.length > 0) {
        const account:AccountInfo = accounts[0];
        
        msalInstance.setActiveAccount(account);
        // const currentAccount:AccountInfo | null = msalInstance.getAccountByUsername(account.username);
        // if(account){
        //     getGroup(account);
        // }
        if(account.username.toLowerCase() === 'arshdeep.singh@chargerlogistics.com' || account.username.toLowerCase() === 'andy@chargerlogistics.com' || account.username.toLowerCase() === 'arshvir@chargerlogistics.com')
        {
          store.commit('setAccountDetails', {account});
          try{
            msalInstance.acquireTokenSilent(loginRequest ).then(tokenResponse => {
              const token = tokenResponse.accessToken;
              store.commit('setAccessToken', {token});
              resolve();
              return
              })
              .catch((e) => {
                this.signIn();
              });
          }
          catch(e){
            msalInstance.acquireTokenPopup(loginRequest ).then(tokenResponse => {
            const token = tokenResponse.accessToken;
            store.commit('setAccessToken', {token});
            resolve();
            return
            })  .catch((e) => {
              this.signIn();
            });
          }
        }
        else{
          this.signOut();
        }
        
      }
      else if(activeAccount){
        const account = activeAccount;
        if(account.username.toLowerCase() === 'arshdeep.singh@chargerlogistics.com' || account.username.toLowerCase() === 'andy@chargerlogistics.com')
        {
          store.commit('setAccountDetails', {account});
          resolve();
          return
        }
        else{
          this.signOut();
        }
      }
      else{
        this.signIn();
      }
    });
  },
  /**
   * @return {Boolean} Indicates if there is a valid, non-expired access token present in localStorage.
   */
  isAuthenticated() {
    // getCachedToken will only return a valid, non-expired token.
    if (store.state.accessToken) { return true; }
    return false;
  },
  /**
   * @return An ADAL user profile object.
   */
  getUserProfile() {
    const accounts = msalInstance.getAllAccounts();
    return accounts[0];
  },

  async signIn() {
     await msalInstance
        .loginRedirect(loginRequest)
       
    },
  async signOut() {
    console.log("Hellos")
    await msalInstance
        .logoutRedirect({})
        .then(() => {
        })
        .catch(error => {
          console.error(error);
        });
  }
}

async function getGroup(account: AccountInfo) {
  const authCode: AuthCodeMSALBrowserAuthenticationProviderOptions  = {
    account: account,
    interactionType: InteractionType.Redirect,
    scopes: ["user.read"]
  }
  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(msalInstance, authCode)
  const Options = {
    authProvider,
  }
  const client = Client.initWithMiddleware(Options);
  const members =  await client.api('/groups/7b5d50b6-9972-4601-b990-a5558fd19bc2/members').get();
  console.log("Members", members);
  return members
}
