import { RouteRecordRaw } from 'vue-router';

export const PublicRoutes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        redirect: '/admin/inbound',
    },
    {
        path: '/admin/inbound',
        name: 'admin.inbound',
        component: () => import('@/modules/Inbound/index.vue'),
    },
    {
        path: '/admin/outbound',
        name: 'admin.outbound',
        component: () => import('@/modules/Outbound/index.vue'),
    },
    {
        path: '/admin/location',
        name: 'admin.location',
        component: () => import('@/modules/Location/index.vue'),
    }
];
