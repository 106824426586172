import { createRouter, createWebHistory } from 'vue-router';
import { PublicRoutes } from './PublicRoutes';
import authentication from '../authentication'

const router = createRouter({
    history: createWebHistory(),
    routes: [...PublicRoutes],
});

export default router
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuthentication)) {
      // this route requires auth, check if logged in
      if (authentication.isAuthenticated()) {
        next();
      } else {
        authentication.signIn();
      }
    } else {
      next();
    }
  });
